import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './domain/dashboard';

function AppRoutes() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Dashboard />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default AppRoutes;
