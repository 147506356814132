interface DataDisplayProps {
  results: any;
  countedObjects: {};
}

function DataDisplay({ results }: DataDisplayProps) {
  return (
    <div className="flex flex-col p-5 overflow-auto h-[90vh]">
      <div className="border-b-2 mb-2">
        <h1>Detected Objects</h1>
      </div>
      <div>
        {results.length
          ? results.map((result: any) => (
              <div key={result.label} className="mb-1 bg-blue-200 p-4">
                {result.label} - {result.confidence.toFixed(2)}%
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export default DataDisplay;
