// import * as ml5 from 'ml5';

import { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

declare var ml5: any;

const dimensions = {
  width: 1080,
  height: 720,
};

interface ObjectDetectionProps {
  setResults: (results: any[]) => void;
  setCountedObjects: (countedObjects: any) => void;
}

function ObjectDetection({ setResults }: ObjectDetectionProps) {
  let isCountingSameObject = false;
  let lastObjectsCounted = [];

  const videoRef: any = useRef();
  const canvasRef: any = useRef();

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    let detectionInterval: any;

    // 1. Once the model has loaded, update the dimensions run the model's detection interval
    const modelLoaded = () => {
      const { width, height } = dimensions;
      videoRef.current.video.width = width;
      videoRef.current.video.height = height;

      // set canvas dimensions
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      detectionInterval = setInterval(() => {
        detect();
      }, 200);
    };

    const objectDetector = ml5.objectDetector('cocossd', modelLoaded);

    const detect = () => {
      if (videoRef.current.video.readyState !== 4) {
        console.warn('Video not ready yet');
        return;
      }

      objectDetector.detect(
        videoRef.current.video,
        (err: any, results: any) => {
          const { width, height } = dimensions;
          ctx.clearRect(0, 0, width, height);
          if (results && results.length) {
            results.forEach((detection: any) => {
              ctx.beginPath();
              ctx.fillStyle = '#FF0000';
              const { label, x, y, width, height } = detection;

              ctx.font = '30px Arial';
              ctx.fillText(label, x + 30, y - 5);
              ctx.rect(x + 30, y, width, height);

              ctx.strokeStyle = 'green';
              ctx.lineWidth = 4;
              ctx.stroke();
            });
            setResults(results);
          } else {
            setResults([]);
          }
        },
      );
    };

    return () => {
      if (detectionInterval) {
        clearInterval(detectionInterval);
      }
    };
  }, []);

  return (
    <div className="border p-2">
      <Webcam
        ref={videoRef}
        className="webcam"
        videoConstraints={{ facingMode: 'environment' }}
      />
      <canvas ref={canvasRef} className="canvas" />
    </div>
  );
}

export default ObjectDetection;
