export const SideMenuItems = [
  {
    label: 'Traffic Analytics',
    slug: 'traffic-data',
    icon: 'pi pi-chart-bar',
    to: '',
  },
  {
    label: 'Live View',
    slug: 'live-view',
    icon: '',
    to: '',
  },
  {
    label: 'Map View',
    slug: 'map-view',
    icon: '',
    to: '',
  },
];
