import AppRoutes from './app.route';
import { PrimeReactProvider } from 'primereact/api';

function App() {
  return (
    <PrimeReactProvider>
      <AppRoutes />;
    </PrimeReactProvider>
  );
}

export default App;
