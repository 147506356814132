import { SideMenuItems } from '../../core/menu/side-menu.options';

import logo from '../img/logo.png';

function SidePanel() {
  return (
    <div className="menu-panel bg-gray-100 w-[15%] flex flex-col border-r-2">
      <div>
        <img src={logo} alt="Bukolabs.io" />
      </div>
      <div className="pt-5">
        {SideMenuItems.map((sideMenuItem) => (
          <button
            key={sideMenuItem.slug}
            className={`p-4 w-full hover:bg-gray-200 text-left ${
              sideMenuItem.slug === 'live-view' ? 'active-menu' : ''
            }`}
          >
            {sideMenuItem.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SidePanel;
