import ObjectDetection from '../core/ui/object-detection';
import { Button } from 'primereact/button';
import SidePanel from '../shared/components/sidePanel';
import DataDisplay from '../shared/components/data-display';
import { useState } from 'react';

function Dashboard() {
  const [results, setResults] = useState({});

  const [countedObjects, setCountedObjects] = useState({
    person: 0,
    // add more labels as needed
  });

  return (
    <div className="flex h-[100vh]">
      <SidePanel />
      <div className="flex flex-col flex-auto">
        <div className="shadow-sm z-10 flex justify-end items-center px-5 py-1">
          <div>
            <Button label="Support" size="small" />
          </div>
        </div>
        <div className="main-view flex-auto flex rounded-l-lg">
          <div className="flex flex-col flex-auto">
            <div className="h-10 px-4 py-2 border-b">additional info</div>
            <div className="p-5">
              <ObjectDetection
                setResults={setResults}
                setCountedObjects={setCountedObjects}
              />
            </div>
          </div>
          <div className="w-[20%] bg-gray-100">
            <DataDisplay results={results} countedObjects={countedObjects} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
